@use "../base/variable" as *;
@use "../base/theme_orange" as *;
@use "../base/icomoon-style";
@use "../base/mixin" as *;;
@use "../base/keyframes";
.l-footer {
  &__contents {
    position: relative;
    max-width: 1216px;
    width: calc(100% - 40px);
    margin: auto;
    display: flex;
    justify-content: center;
    padding: 2em 0 1em 0;
    color: $bc_white;
    @include media(lg) {
      padding: 40px 20px 10px;
      display: block;
      width: 100%;
    }
  }
  &__column {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  &__logo {
    width: 200px;
    max-width: 100%;
    padding: 0 0 20px;
    text-align: center;
    margin: 0 auto;
    @include media(lg) {
      width: 100%;
      padding: 0 0 40px;
    }
    @include media(sp) {
      padding: 0 0 20px;
      max-width: 150px;
      width: 100%;
    }
  }
  &__iconsTitle {
    font-size: 1.4rem;
    letter-spacing: .15em;
    margin-bottom: 1.5em;
    position: relative;
    @include media(lg) {
      display: none;
    }
    &::before{
      width: 140px;
      height: 1px;
      background-color: #fff;
      position: absolute;
      left: 0;
      top: 50%;
      content: '';
    }
  }
  &__icons {
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 250px;
    @include media(lg) {
      display: block;
      width: auto;
      margin: 0 auto;
    }
  }
  .snsIcons {
    width: auto;
    text-align: right;
    display: none;
    @include media(lg) {
      width: auto;
      margin: 0;
    }
    @include media(tab) {
      width: 100%;
    }
    &__list {
      display: flex;
      justify-content: space-between;
      align-items: stretch;
      flex-wrap: wrap;
      // width: 100px;
      // height: 100px;
      font-size: 3.8rem;
      line-height: 1;
      margin-bottom: 20px;
      @include media(lg) {
        justify-content: center;
        align-items: center;
        width: auto;
        height: auto;
      }
    }
    &__item {
      @include media(lg) {
        margin: .8em .2em .5em;
      }
    }
  }
}
.pageLinks {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  flex-wrap: wrap;
  font-size: 1.3rem;
  width: calc(380px + 1em);
  @include media(lg) {
    max-width: calc(380px + 1em);
    margin: 0 auto;
    width: 100%;
  }
  &__item {
    @include media(lg) {
      margin: .5em;
    }
  }
}
.footerNavi {
  width: 35%;
  margin-left: 3%;
  @include media(lg) {
    display: none;
  }
  &__list {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: stretch;
    font-size: 1.8rem;
    width: 100%;
    height: 200px;
    border-right: solid 1px $bc_white;
    line-height: 1.2;
  }
  &__item {
    width: 8em;
    margin-bottom: 1em;
  }
}
.copyright {
  letter-spacing: .05em;
  margin-top: 20px;
  text-align: center;
}

.page_top{
  position: absolute;
  top: 20px;
  right: -60px;
  @media screen and (max-width: 1346px) {
    right: 10px;
  }
  span{
    font-size: 16px;
    writing-mode: vertical-rl;
    padding-top: 8px;
  }
}
